import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { makeStyles } from "@material-ui/core/styles";
export var useInputStyles = makeStyles(function(theme) {
    return {
        root: {
            border: "1px solid",
            borderRadius: 2,
            height: theme.spacing(7),
            minHeight: theme.spacing(7),
            transition: "0.2s all"
        },
        input: {
            padding: function(param) {
                var padding = param.padding;
                return "".concat(padding);
            },
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0
            },
            "&[type=number]": {
                "-moz-appearance": "textfield"
            }
        },
        error: {},
        focused: {
            border: "2px solid !important"
        }
    };
});
export var useStyles = makeStyles(function(theme) {
    return {
        error: _object_spread_props(_object_spread({}, theme.typography.c3), {
            marginTop: theme.spacing(1)
        })
    };
});
