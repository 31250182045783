import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import * as FullStory from "@fullstory/browser";
import * as Sentry from "@sentry/browser";
import { RewriteFrames } from "@sentry/integrations";
import * as SentryNode from "@sentry/node";
import getConfig from "next/config";
import * as FullStoryUtil from "./fullstory";
import { isServer } from "./server";
export function addSentryScopeTag(name, value) {
    Sentry.configureScope(function(scope) {
        scope.setTag(name, value);
    });
}
export function addSentryScopeUser(user) {
    Sentry.configureScope(function(scope) {
        scope.setUser(user);
    });
}
export function captureMessage(error, statusCode) {
    if (statusCode) {
        Sentry.setTag("error.code", String(statusCode));
    }
    Sentry.setTag("source", isServer() ? "lambda" : "static");
    statusCode === 404 ? Sentry.captureMessage(error.message) : Sentry.captureException(error);
    if (isServer()) {
        Sentry.flush(2000);
    }
}
export function initSentry() {
    var config = getConfig();
    var distDir = "".concat(config.serverRuntimeConfig.rootDir, "/.next");
    var isEnabled = process.env.APP_STAGE !== "development";
    var integrations = function(defaults) {
        var customIntegrations = [
            new RewriteFrames({
                iteratee: function(frame) {
                    var ref;
                    frame.filename = (ref = frame.filename) === null || ref === void 0 ? void 0 : ref.replace(distDir, "app:///_next");
                    return frame;
                }
            }), 
        ];
        return _to_consumable_array(defaults).concat(_to_consumable_array(customIntegrations));
    };
    var ignoreErrors = [
        // Facebook related from https://connect.facebook.net/en_US/iab.autofill.payment.js
        // Issue: https://sentry.io/organizations/finn-gmbh/issues/1885723930/?environment=production&project=2460897
        "Unexpected token s in JSON at position 0",
        'JSON Parse error: Unexpected identifier "setImmediate$0"',
        'JSON Parse error: Unexpected identifier "object"',
        "JSON Parse error: Unexpected EOF",
        /Request aborted/,
        "ResizeObserver loop limit exceeded", 
    ];
    var sentryOptions = {
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
        environment: process.env.APP_STAGE,
        enabled: isEnabled,
        integrations: integrations,
        ignoreErrors: ignoreErrors,
        tracesSampleRate: 0.1,
        beforeSend: function beforeSend(event, hint) {
            var error = hint.originalException;
            var errorMessage = typeof error === "string" ? error : error === null || error === void 0 ? void 0 : error.message;
            // This to ignore the "Illegal invocation" error that caused by FB crawlers
            if (!isServer()) {
                var hasFBCLID = window.location.search.includes("fbclid");
                var isIllegalInvocationError = errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.match(/Illegal invocation/i);
                if (hasFBCLID && isIllegalInvocationError) {
                    return null;
                }
            }
            if (errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.match(/\/_next\//)) {
                console.warn(error);
                return null;
            }
            if (FullStoryUtil.isFullStoryLoaded()) {
                event.contexts = _object_spread_props(_object_spread({}, event.contexts), {
                    fullStory: {
                        fullStoryUrl: FullStory.getCurrentSessionURL(true) || "current session URL API not ready"
                    }
                });
                // FS.event is immediately ready even if FullStory isn't fully bootstrapped
                FullStory.event("Sentry Error", _object_spread({
                    sentryUrl: FullStoryUtil.getSentryUrl(hint)
                }, FullStoryUtil.getOriginalExceptionProperties(hint)));
            }
            return event;
        },
        release: process.env.COMMIT_REF || "preview"
    };
    if (!isServer()) {
        sentryOptions.denyUrls = [
            "https://connect.facebook.net/en_US/iab.autofill.payment.js", 
        ];
        Sentry.init(sentryOptions);
    } else {
        SentryNode.init(sentryOptions);
    }
    Sentry.configureScope(function(scope) {
        scope.setTag("nodejs", process.version);
    });
}
export function addSentryScopeBreadcrumb(category, message, level) {
    Sentry.configureScope(function(scope) {
        scope.addBreadcrumb({
            category: category,
            message: message,
            level: level
        });
    });
}
export function addSentryRenderPageBreadcrumb(fileLabel, title) {
    var source = isServer() ? "server" : "browser";
    var message = "Rendering page ".concat(title, " (").concat(source, ")");
    addSentryScopeBreadcrumb(fileLabel, message, Sentry.Severity.Debug);
}
