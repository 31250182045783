import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import regeneratorRuntime from "/home/runner/work/finn-auto-ui-frontend/finn-auto-ui-frontend/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { getGlobalObject } from "../helpers/globalObject";
var emmitAndAdaptForApp = function(emitEvent) {
    return function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(route, as, params) {
            var url, DELAY_FOR_APP_TO_START_ANIMATION;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        url = typeof route === "string" ? route : route.pathname;
                        if (!(params === null || params === void 0 ? void 0 : params.appFirst)) {
                            _ctx.next = 4;
                            break;
                        }
                        delete params.appFirst;
                        return _ctx.abrupt("return", [
                            url,
                            as,
                            params
                        ]);
                    case 4:
                        emitEvent({
                            type: "event:navigate",
                            value: url
                        });
                        DELAY_FOR_APP_TO_START_ANIMATION = 33;
                        _ctx.next = 8;
                        return new Promise(function(resolve) {
                            return setTimeout(resolve, DELAY_FOR_APP_TO_START_ANIMATION);
                        });
                    case 8:
                        return _ctx.abrupt("return", [
                            url,
                            as,
                            params
                        ]);
                    case 9:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        }));
        return function(route, as, params) {
            return _ref.apply(this, arguments);
        };
    }();
};
var proxyHandler = function(emitEvent) {
    return function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(target, thisArg, param) {
            var _param, route, as, params, args;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        _param = _sliced_to_array(param, 3), route = _param[0], as = _param[1], params = _param[2];
                        _ctx.next = 3;
                        return emmitAndAdaptForApp(emitEvent)(route, as, params);
                    case 3:
                        args = _ctx.sent;
                        return _ctx.abrupt("return", target.apply(thisArg, args));
                    case 5:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        }));
        return function(target, thisArg, _) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var setupNavigation = function(emitEvent) {
    var globalObject = getGlobalObject();
    var applyBindings = function() {
        if (globalObject.next) {
            globalObject.next.router.push = new Proxy(globalObject.next.router.push, {
                apply: proxyHandler(emitEvent)
            });
            globalObject.next.router.replace = new Proxy(globalObject.next.router.push, {
                apply: proxyHandler(emitEvent)
            });
        }
    };
    if (globalObject.next) {
        applyBindings();
    } else {
        setTimeout(applyBindings, 500);
    }
};
