import _define_property from "@swc/helpers/src/_define_property.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
/** Generate a random unique-enough string */ function generateId() {
    return Math.random().toString(36).substring(2);
}
export var toastManagerStore = create()(devtools(function(set) {
    return {
        toastsById: {},
        toastIds: [],
        showToast: function showToast(toastData) {
            var id = generateId();
            set(function(prevState) {
                var toast = _object_spread_props(_object_spread({}, toastData), {
                    id: id,
                    createdAt: Date.now()
                });
                return {
                    toastsById: _object_spread_props(_object_spread({}, prevState.toastsById), _define_property({}, id, toast)),
                    toastIds: _to_consumable_array(prevState.toastIds).concat([
                        id
                    ])
                };
            }, false, "toastManager/showToast");
            return id;
        },
        dismissToast: function dismissToast(toastId) {
            set(function(prevState) {
                var nextToastsById = _object_spread({}, prevState.toastsById);
                delete nextToastsById[toastId];
                var nextToastIds = prevState.toastIds.filter(function(id) {
                    return id !== toastId;
                });
                return {
                    toastsById: nextToastsById,
                    toastIds: nextToastIds
                };
            }, false, "toastManager/dismissToast");
        }
    };
}, {
    name: "toastManager"
}));
export function showToast(toastData) {
    return toastManagerStore.getState().showToast(toastData);
}
export function dismissToast(toastId) {
    return toastManagerStore.getState().dismissToast(toastId);
}
