import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/runner/work/finn-auto-ui-frontend/finn-auto-ui-frontend/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
export var sha256 = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(message) {
        var msgBuffer, hashBuffer, hashArray;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.prev = 0;
                    msgBuffer = new window.TextEncoder().encode(message);
                    _ctx.next = 4;
                    return window.crypto.subtle.digest("SHA-256", msgBuffer);
                case 4:
                    hashBuffer = _ctx.sent;
                    hashArray = Array.from(new window.Uint8Array(hashBuffer));
                    return _ctx.abrupt("return", hashArray.map(function(b) {
                        return b.toString(16).padStart(2, "0");
                    }).join(""));
                case 9:
                    _ctx.prev = 9;
                    _ctx.t0 = _ctx["catch"](0);
                    return _ctx.abrupt("return", undefined);
                case 12:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                0,
                9
            ]
        ]);
    }));
    return function sha256(message) {
        return _ref.apply(this, arguments);
    };
}();
