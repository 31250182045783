import { isServer } from "./server";
export var isFullStoryLoaded = function() {
    return !isServer() && window.FS;
};
export var getSentryUrl = function(hint) {
    var dsn = process.env.SENTRY_DSN;
    var sentryOrg = process.env.SENTRY_ORG;
    var projectId = process.env.SENTRY_PROJECT;
    if (!hint || !dsn || !sentryOrg || !projectId) {
        return "Could not retrieve url";
    }
    return "https://sentry.io/organizations/".concat(sentryOrg, "/issues/?project=").concat(projectId, "&query=").concat(hint.event_id);
};
// Below code is taken from https://github.com/getsentry/sentry-fullstory/blob/master/src/util.ts
var isError = function(exception) {
    return exception.message !== undefined;
};
/**
 * Get the message and name properties from the original exception
 * @param {EventHint} hint
 */ export var getOriginalExceptionProperties = function(hint) {
    if (hint && hint.originalException && isError(hint.originalException)) {
        var originalException = hint.originalException;
        var name = originalException.name, message = originalException.message;
        return {
            name: name,
            message: message
        };
    }
    return {};
};
