import { useMemo } from "react";
import { getKmPackagePriceWithoutVat } from "../pdp";
import { getForBusinessCookie } from "../ProductCard/forBusiness";
import { getLongestTerm } from "./getLongestTerm";
export var getPriceList = function(param) {
    var vehicle = param.vehicle, isDownPayment = param.isDownPayment, isFakedoorDownpayment = param.isFakedoorDownpayment;
    if (isDownPayment && !isFakedoorDownpayment) {
        return vehicle === null || vehicle === void 0 ? void 0 : vehicle.downpayment_prices.available_price_list;
    }
    return vehicle === null || vehicle === void 0 ? void 0 : vehicle.price.available_price_list;
};
export var getBasePrice = function(param) {
    var term = param.term, priceList = param.priceList, isForBusiness = param.isForBusiness;
    var priceKey = "".concat(isForBusiness ? "b2b" : "b2c", "_").concat(term);
    var ref;
    return (ref = priceList === null || priceList === void 0 ? void 0 : priceList[priceKey]) !== null && ref !== void 0 ? ref : 0;
};
var getKilometerPackagePrice = function(param) {
    var vehicle = param.vehicle, isForBusiness = param.isForBusiness, kilometerPackage = param.kilometerPackage;
    var ref;
    var ref1;
    var packagePrice = (ref1 = vehicle === null || vehicle === void 0 ? void 0 : (ref = vehicle.price) === null || ref === void 0 ? void 0 : ref["extra_".concat(kilometerPackage)]) !== null && ref1 !== void 0 ? ref1 : 0;
    return getKmPackagePriceWithoutVat(packagePrice, isForBusiness);
};
var calculateDownpaymentDiscount = function(param) {
    var term = param.term, downPaymentAmount = param.downPaymentAmount, isFakedoorDownpayment = param.isFakedoorDownpayment;
    if (term === 0) {
        return 0;
    }
    if (isFakedoorDownpayment) {
        return downPaymentAmount / term;
    }
    return downPaymentAmount / term * 0.97;
};
export var calculateTotalPrice = function(param) {
    var term = param.term, vehicle = param.vehicle, _kilometerPackage = param.kilometerPackage, kilometerPackage = _kilometerPackage === void 0 ? 0 : _kilometerPackage, _isDownPayment = param.isDownPayment, isDownPayment = _isDownPayment === void 0 ? false : _isDownPayment, _downPaymentAmount = param.downPaymentAmount, downPaymentAmount = _downPaymentAmount === void 0 ? 0 : _downPaymentAmount, _isFakedoorDownpayment = param.isFakedoorDownpayment, isFakedoorDownpayment = _isFakedoorDownpayment === void 0 ? false : _isFakedoorDownpayment;
    var isForBusiness = getForBusinessCookie();
    var adjustedTerm = term || getLongestTerm(vehicle);
    var priceList = getPriceList({
        vehicle: vehicle,
        isDownPayment: isDownPayment,
        downPaymentAmount: downPaymentAmount,
        isFakedoorDownpayment: isFakedoorDownpayment
    });
    var basePrice = getBasePrice({
        priceList: priceList,
        isForBusiness: isForBusiness,
        term: adjustedTerm
    });
    var kmPackagePrice = getKilometerPackagePrice({
        vehicle: vehicle,
        isForBusiness: isForBusiness,
        kilometerPackage: kilometerPackage
    });
    var totalPrice = basePrice + kmPackagePrice;
    if (downPaymentAmount > 0 && isFakedoorDownpayment && isDownPayment) {
        var downpaymentDiscount = calculateDownpaymentDiscount({
            downPaymentAmount: downPaymentAmount,
            term: adjustedTerm,
            isFakedoorDownpayment: isFakedoorDownpayment
        });
        return totalPrice - downpaymentDiscount;
    }
    return totalPrice;
};
export var useCalculatePrice = function(params) {
    return useMemo(function() {
        return calculateTotalPrice(params);
    }, [
        params
    ]);
};
