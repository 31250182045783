import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/runner/work/finn-auto-ui-frontend/finn-auto-ui-frontend/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import axios from "axios";
import useSWR from "swr";
// todo: this endpoint is visible from the client side, consider moving to the server side,
//       moreover, the response exposes some data which may be sensitive
export var RETOOL_URL = "https://one.finn.auto/retool/v1/workflows/b4a3d43c-e956-4839-b881-b123458df491/startTrigger?workflowApiKey=retool_wk_e186d48bfbb641e2a7017fb3dd5c5a6f";
export var DEDUPING_INTERVAL = 300000;
var fetcher = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(param) {
        var url, car_id;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    url = param.url, car_id = param.car_id;
                    return _ctx.abrupt("return", axios.post(url, {
                        car_id: car_id
                    }, {
                        withCredentials: false
                    }));
                case 2:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function fetcher(_) {
        return _ref.apply(this, arguments);
    };
}();
export var useCarSaleInfo = function(carId) {
    return useSWR(carId ? {
        url: RETOOL_URL,
        car_id: carId
    } : null, fetcher, {
        keepPreviousData: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        errorRetryCount: 0,
        dedupingInterval: DEDUPING_INTERVAL
    });
};
