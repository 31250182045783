import { ageBoundaries } from "@finn/ua-constants";
import { CountryCode, getAgeInYears } from "@finn/ui-utils";
import * as Yup from "yup";
// 1 MB = 1024 KB
// 1 KB = 1024 B
// This mean 10MB
var MAX_FILE_SIZE = 10485760;
var SUPPORTED_FILES_TYPES = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "application/pdf",
    "image/heic", 
];
export var validateFile = function(key, side) {
    var isRequired = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true;
    var isFrontEnd = side === "frontend";
    // Because we need Localization keys in frontend messages
    var validationMessages = {
        required: isFrontEnd ? "yup.required" : "".concat(key, " is required"),
        format: isFrontEnd ? "yup.unsupportedFileFormat" : "".concat(key, " has Unsupported Format (png / jpg / pdf / heic)"),
        size: isFrontEnd ? "yup.MaxSize10" : "".concat(key, " size too large (Max size is 10 MB)")
    };
    var schema = isRequired ? Yup.mixed().required(validationMessages.required) : Yup.mixed().notRequired();
    return schema.test("fileSize", validationMessages.size, function(value) {
        return value ? value.size <= MAX_FILE_SIZE : true;
    }).test("fileFormat", validationMessages.format, function(value) {
        return value ? SUPPORTED_FILES_TYPES.includes(value === null || value === void 0 ? void 0 : value.type.toLowerCase()) : true;
    });
};
// 1. Phone number length should be bigger than 8
// 2. First character has to be “+” (the new input library does it automatically)
// 3. After first character it should only contain numbers/dashs/spaces and paranthesis
export var validGlobalPhoneNumber = /^(\+){1}[()\s\d-]{9,}$/;
export var validAmericanPhoneNumber = /^((\+)1\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export var getValidationRegex = function(param) {
    var phoneNumber = param.phoneNumber;
    var countryCode = phoneNumber.split(" ")[0];
    switch(countryCode){
        case "+1":
            return validAmericanPhoneNumber;
        default:
            return validGlobalPhoneNumber;
    }
};
export var checkPhoneNumberValidation = function(phone, region) {
    var allowGlobalNumber = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true;
    var phoneNumber = phone !== null && phone !== void 0 ? phone : "";
    var isDE = region === CountryCode.DE;
    var validationRegex = isDE || allowGlobalNumber ? getValidationRegex({
        phoneNumber: phoneNumber
    }) : validAmericanPhoneNumber;
    var isValidPhoneNumber = validationRegex.test(phoneNumber);
    return isValidPhoneNumber;
};
export var isZipCodeValidForGermany = function(zipCode) {
    // very basic validation to ensure digits and length = 5
    var zipCodeRegex = /^\d{5}$/;
    return zipCodeRegex.test(zipCode);
};
export var validGermanZipCode = function() {
    var testName = "zipCode";
    return Yup.string().required("yup.required").test(testName, "yup.validZipCode", isZipCodeValidForGermany).nullable();
};
export var isValidAge = function(value, region) {
    var minAge = region === CountryCode.US ? ageBoundaries.MIN_EXCEPTIONAL_US : ageBoundaries.MIN_EXCEPTIONAL;
    return getAgeInYears(value) >= minAge;
};
// Phone number must be at least 5 characters
export var isValidPhone = function(key) {
    var region = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : CountryCode.DE, allowGlobalNumber = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true;
    return Yup.string().required("yup.required").test(key, "yup.validPhoneNumber", function(value) {
        return checkPhoneNumberValidation(value, region, allowGlobalNumber);
    }).nullable();
};
export var validNameRegex = RegExp("^[a-zA-Z\xe0\xe1\xe2\xe4\xe3\xe5ąčćę\xe8\xe9\xea\xebėį\xec\xed\xee\xefłń\xf2\xf3\xf4\xf6\xf5\xf8\xf9\xfa\xfb\xfcųū\xff\xfdżź\xf1\xe7čšž\xc0\xc1\xc2\xc4\xc3\xc5ĄĆČĖĘ\xc8\xc9\xca\xcb\xcc\xcd\xce\xcfĮŁŃ\xd2\xd3\xd4\xd6\xd5\xd8\xd9\xda\xdb\xdcŲŪŸ\xddŻŹ\xd1\xdf\xc7Œ\xc6ČŠŽ∂\xf0 ,.'-]+$", "u");
export var validCompanyNameRegex = RegExp("^[a-zA-Z0-9\xe0\xe1\xe2\xe4\xe3\xe5ąčćę\xe8\xe9\xea\xebėį\xec\xed\xee\xefłń\xf2\xf3\xf4\xf6\xf5\xf8\xf9\xfa\xfb\xfcųū\xff\xfdżź\xf1\xe7čšž\xc0\xc1\xc2\xc4\xc3\xc5ĄĆČĖĘ\xc8\xc9\xca\xcb\xcc\xcd\xce\xcfĮŁŃ\xd2\xd3\xd4\xd6\xd5\xd8\xd9\xda\xdb\xdcŲŪŸ\xddŻŹ\xd1\xdf\xc7Œ\xc6ČŠŽ∂\xf0 \\-!$%^&*()_+|~=`\"'{}[:;<>?,.@#\\]]+$", "u");
/*
  * Here is an explanation:

    /^
      (?=.*\d)          // should contain at least one digit
      (?=.*[a-zA-Z])    // should contain at least one letter
      [a-zA-Z0-9!@#$%^&*)(+=._-]{8,}   // should contain at least 8 from the mentioned characters
    $/
   */ export var validatePasswordRegex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
