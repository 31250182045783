export var FormSlug;
(function(FormSlug) {
    FormSlug["B2B"] = "form-b2b";
    FormSlug["CONTACT_US"] = "form-contact";
    FormSlug["REFERRAL_VOUCHER"] = "form-referral-voucher";
    FormSlug["RETENTION_VOUCHER"] = "form-retention-voucher";
    FormSlug["JOBAUTO"] = "form-jobauto-version-2";
    FormSlug["PARTNERSHIPS"] = "form-partnerships";
    FormSlug["CANCELATION"] = "form-cancelation";
    FormSlug["CANCELATION_CONFIRMATION"] = "form-cancelation-confirmation";
    FormSlug["B2B_LEAD_GENERATION_FORM"] = "form-b2b-lead-generation";
    FormSlug["PREQUALIFY"] = "form-prequalify";
})(FormSlug || (FormSlug = {}));
export var FlipperSlug;
(function(FlipperSlug) {
    FlipperSlug["FINN_FOR_FRIENDS"] = "flipper-finnforfriends";
})(FlipperSlug || (FlipperSlug = {}));
export var TextblockSlug;
(function(TextblockSlug) {
    TextblockSlug["EON_VOUCHER"] = "textblock-eon-voucher";
})(TextblockSlug || (TextblockSlug = {}));
export var DashboardSlug;
(function(DashboardSlug) {
    DashboardSlug["B2B_HANDOVER"] = "data-dashboard-b2b-handover-coordination";
    DashboardSlug["B2B_RETURN"] = "data-dashboard-b2b-return-coordination";
})(DashboardSlug || (DashboardSlug = {}));
export var HeroSlug;
(function(HeroSlug) {
    HeroSlug["HOME"] = "new-homepage-hero";
})(HeroSlug || (HeroSlug = {}));
