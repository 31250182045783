import { Features, useHasCampaing, useIsABVariant } from "@finn/ua-featureflags";
import { useCurrentLocale } from "@finn/ui-utils";
export var useDownPayment = function() {
    var hasCampaign = useHasCampaing();
    var hideDownPayment = useIsABVariant(Features.HideDownPayment);
    var isDE = useCurrentLocale().isDE;
    if (hideDownPayment || hasCampaign || !isDE) {
        return {
            isDownPaymentEnabled: false
        };
    }
    return {
        isDownPaymentEnabled: true
    };
};
