// TODO delete this after typography and colors migrated for DS
export var colors = {
    red: "#E71D3F",
    error: "#A70822",
    green: "#26BC58",
    snow: "#F8F8F8",
    cotton: "#F3F3F3",
    pearl: "#E9EAEC",
    pewter: "#D7D7D7",
    steel: "#979797",
    iron: "#707070",
    shadow: "#272B3E",
    orange: "#EA7E00",
    lightBlue: "#D9EDFC",
    white: "#FFFFFF",
    black: "#191919",
    primary: "#0086EA",
    lightPink: "#FCDFE4",
    mintGreen: "#DEF5E6",
    aaGrey: "#737373"
};
