export var FinancingType;
(function(FinancingType) {
    FinancingType["ABS_CREDIT_SUISSE_1"] = "abs_creditsuisse_1";
    FinancingType["FINN"] = "finn";
})(FinancingType || (FinancingType = {}));
export var FinancingTypeUS;
(function(FinancingTypeUS) {
    FinancingTypeUS["FINN"] = "finn_us";
    FinancingTypeUS["ABS_FASTONE"] = "abs_fastone";
    FinancingTypeUS["LEASING_INSPIRATIONMOBILITY"] = "leasing_inspirationmobility";
    FinancingTypeUS["FINN_US_VCI"] = "finn_us_vci";
})(FinancingTypeUS || (FinancingTypeUS = {}));
export var LessorUS;
(function(LessorUS) {
    LessorUS["FINN_OF_AMERICA"] = "finn of America Inc.";
    LessorUS["FINN_TITLING_TRUST"] = "FINN Titling Trust";
})(LessorUS || (LessorUS = {}));
export var EntityTypeUS;
(function(EntityTypeUS) {
    EntityTypeUS["TRUST"] = "a Trust";
    EntityTypeUS["DELAWARE_CORPORATION"] = "a Delaware Corporation";
})(EntityTypeUS || (EntityTypeUS = {}));
export var VehicleOfferType;
(function(VehicleOfferType) {
    VehicleOfferType["SALES"] = "sales";
    VehicleOfferType["SUBSCRIPTION"] = "subscription";
})(VehicleOfferType || (VehicleOfferType = {}));
export var ContractTermType;
(function(ContractTermType) {
    ContractTermType["OPEN"] = "open-ended";
    ContractTermType["FIXED"] = "fixed";
})(ContractTermType || (ContractTermType = {}));
export var ADDITIONAL_PAYMENT_METHODS;
(function(ADDITIONAL_PAYMENT_METHODS) {
    ADDITIONAL_PAYMENT_METHODS["JOB_AUTO"] = "jobauto";
})(ADDITIONAL_PAYMENT_METHODS || (ADDITIONAL_PAYMENT_METHODS = {}));
