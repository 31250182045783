import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import regeneratorRuntime from "/home/runner/work/finn-auto-ui-frontend/finn-auto-ui-frontend/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { clearClientCookie, CookieKeys, getClientBooleanCookie, isServer, traceabilityHeadersBrowser } from "@finn/ui-utils";
import * as Sentry from "@sentry/node";
import axios from "axios";
import { signIn, signOut as nextAuthSignOut } from "next-auth/client";
import { setClientBooleanCookie } from "./cookies";
var BASE_URL = "".concat(!isServer() ? window.origin : process.env.NEXTAUTH_URL || "", "/api/auth");
var SERVER_ERROR = "server_error";
var callSignIn = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(provider, options) {
        var ref, ref1, data;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.prev = 0;
                    ;
                    _ctx.next = 4;
                    return signIn(provider, _object_spread({
                        redirect: false
                    }, typeof (window === null || window === void 0 ? void 0 : window.nativeAppSDK) !== "undefined" ? {
                        appVersion: window === null || window === void 0 ? void 0 : (ref = window.nativeAppSDK) === null || ref === void 0 ? void 0 : ref.getTrackingProps().app_version
                    } : {}, options));
                case 4:
                    data = _ctx.sent;
                    data.ok = data.ok && !data.error;
                    if (!((ref1 = data.url) === null || ref1 === void 0 ? void 0 : ref1.includes("csrf=true"))) {
                        _ctx.next = 9;
                        break;
                    }
                    if ("password" in options) {
                        delete options.password;
                    }
                    throw new Error("Invalid CSRF token for callbacks. Callback Provider: ".concat(provider, " Options: ").concat(JSON.stringify(options)));
                case 9:
                    return _ctx.abrupt("return", data);
                case 12:
                    _ctx.prev = 12;
                    _ctx.t0 = _ctx["catch"](0);
                    Sentry.captureException(_ctx.t0);
                    _ctx.next = 17;
                    return Sentry.flush(2000);
                case 17:
                    return _ctx.abrupt("return", {
                        status: 500,
                        ok: false,
                        error: SERVER_ERROR,
                        url: null
                    });
                case 18:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                0,
                12
            ]
        ]);
    }));
    return function callSignIn(provider, options) {
        return _ref.apply(this, arguments);
    };
}();
var callCustomEndpoint = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(endpoint, payload) {
        var status, data, result, ref, ref1, error;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    status = 500;
                    data = null;
                    _ctx.prev = 2;
                    _ctx.next = 5;
                    return axios.post("".concat(BASE_URL, "/custom/").concat(endpoint), payload, {
                        headers: traceabilityHeadersBrowser()
                    });
                case 5:
                    result = _ctx.sent;
                    status = result.status;
                    data = result.data;
                    _ctx.next = 18;
                    break;
                case 10:
                    _ctx.prev = 10;
                    _ctx.t0 = _ctx["catch"](2);
                    ;
                    error = _ctx.t0;
                    Sentry.captureException(error);
                    _ctx.next = 17;
                    return Sentry.flush(2000);
                case 17:
                    return _ctx.abrupt("return", {
                        status: status,
                        ok: false,
                        error: error.response.status === 404 && ((ref = error.response) === null || ref === void 0 ? void 0 : (ref1 = ref.data) === null || ref1 === void 0 ? void 0 : ref1.message) || SERVER_ERROR,
                        url: null
                    });
                case 18:
                    return _ctx.abrupt("return", {
                        status: status,
                        ok: status === 200 && data.success,
                        error: data.message,
                        url: null
                    });
                case 19:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                2,
                10
            ]
        ]);
    }));
    return function callCustomEndpoint(endpoint, payload) {
        return _ref.apply(this, arguments);
    };
}();
export var register = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(email, password, locale, token) {
        var e2eSecret, extendedFields, ref, firstname, lastname, phone, birthday, _args = arguments;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    e2eSecret = _args.length > 4 && _args[4] !== void 0 ? _args[4] : "", extendedFields = _args.length > 5 ? _args[5] : void 0;
                    ref = extendedFields || {}, firstname = ref.firstname, lastname = ref.lastname, phone = ref.phone, birthday = ref.birthday;
                    _ctx.next = 4;
                    return callSignIn("register", {
                        email: email,
                        password: password,
                        firstname: firstname,
                        lastname: lastname,
                        phone: phone,
                        birthday: birthday,
                        locale: locale,
                        token: token,
                        e2eSecret: e2eSecret
                    });
                case 4:
                    return _ctx.abrupt("return", _ctx.sent);
                case 5:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function register(email, password, locale, token) {
        return _ref.apply(this, arguments);
    };
}();
export var getCsrfToken = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
        var result;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return axios.get("".concat(BASE_URL, "/csrf"));
                case 2:
                    result = _ctx.sent;
                    return _ctx.abrupt("return", result.data.csrfToken);
                case 4:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function getCsrfToken() {
        return _ref.apply(this, arguments);
    };
}();
export var login = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(email, password) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return callSignIn("login", {
                        email: email,
                        password: password
                    });
                case 2:
                    return _ctx.abrupt("return", _ctx.sent);
                case 3:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function login(email, password) {
        return _ref.apply(this, arguments);
    };
}();
export var loginWithCode = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(code) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return callSignIn("loginWithCode", {
                        code: code
                    });
                case 2:
                    return _ctx.abrupt("return", _ctx.sent);
                case 3:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function loginWithCode(code) {
        return _ref.apply(this, arguments);
    };
}();
export var loginWithMagicLink = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(token) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return callSignIn("loginWithMagicLink", {
                        token: token
                    });
                case 2:
                    return _ctx.abrupt("return", _ctx.sent);
                case 3:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function loginWithMagicLink(token) {
        return _ref.apply(this, arguments);
    };
}();
export var signOut = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
        var signOutInProgress, expDate;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    if (!isServer()) {
                        signOutInProgress = getClientBooleanCookie(CookieKeys.SIGNOUT_IN_PROGRESS);
                        // prevent already set cookie from being overwritten with new expiry
                        if (!signOutInProgress) {
                            expDate = new Date();
                            expDate.setTime(expDate.getTime() + 3 * 1000); // 3 seconds
                            setClientBooleanCookie(CookieKeys.SIGNOUT_IN_PROGRESS, true, expDate);
                        }
                        clearClientCookie(CookieKeys.LAST_URL);
                    }
                    _ctx.next = 3;
                    return callCustomEndpoint("refreshCode", {});
                case 3:
                    _ctx.next = 5;
                    return nextAuthSignOut({
                        redirect: false
                    });
                case 5:
                    return _ctx.abrupt("return", _ctx.sent);
                case 6:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function signOut() {
        return _ref.apply(this, arguments);
    };
}();
export var fetchAuthToken = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
        var res, data;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return axios.get("".concat(BASE_URL, "/custom/authToken"));
                case 2:
                    res = _ctx.sent;
                    data = res.data;
                    return _ctx.abrupt("return", data);
                case 5:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function fetchAuthToken() {
        return _ref.apply(this, arguments);
    };
}();
export var fetchSession = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
        var res, data;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return axios.get("".concat(BASE_URL, "/session"));
                case 2:
                    res = _ctx.sent;
                    data = res.data;
                    return _ctx.abrupt("return", data);
                case 5:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function fetchSession() {
        return _ref.apply(this, arguments);
    };
}();
export var loginWithToken = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(token) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return callSignIn("loginWithToken", {
                        token: token
                    });
                case 2:
                    return _ctx.abrupt("return", _ctx.sent);
                case 3:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function loginWithToken(token) {
        return _ref.apply(this, arguments);
    };
}();
export var forgotPassword = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(email, locale) {
        var payload;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    payload = {
                        email: email,
                        locale: locale
                    };
                    _ctx.next = 3;
                    return callCustomEndpoint("forgotPassword", payload);
                case 3:
                    return _ctx.abrupt("return", _ctx.sent);
                case 4:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function forgotPassword(email, locale) {
        return _ref.apply(this, arguments);
    };
}();
export var changePassword = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(old_password, password, locale) {
        var csrf_token, payload;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return getCsrfToken();
                case 2:
                    csrf_token = _ctx.sent;
                    payload = {
                        old_password: old_password,
                        password: password,
                        locale: locale,
                        csrf_token: csrf_token
                    };
                    _ctx.next = 6;
                    return callCustomEndpoint("changePassword", payload);
                case 6:
                    return _ctx.abrupt("return", _ctx.sent);
                case 7:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function changePassword(old_password, password, locale) {
        return _ref.apply(this, arguments);
    };
}();
export var changePasswordWithCode = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(code, new_password, locale) {
        var csrf_token, payload;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return getCsrfToken();
                case 2:
                    csrf_token = _ctx.sent;
                    payload = {
                        code: code,
                        new_password: new_password,
                        locale: locale,
                        csrf_token: csrf_token
                    };
                    _ctx.next = 6;
                    return callCustomEndpoint("changePasswordWithCode", payload);
                case 6:
                    return _ctx.abrupt("return", _ctx.sent);
                case 7:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function changePasswordWithCode(code, new_password, locale) {
        return _ref.apply(this, arguments);
    };
}();
export var resendVerification = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(email, locale) {
        var nextUrl, payload, _args = arguments;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    nextUrl = _args.length > 2 && _args[2] !== void 0 ? _args[2] : "";
                    payload = {
                        email: email,
                        locale: locale,
                        nextUrl: nextUrl
                    };
                    _ctx.next = 4;
                    return callCustomEndpoint("resendVerification", payload);
                case 4:
                    return _ctx.abrupt("return", _ctx.sent);
                case 5:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function resendVerification(email, locale) {
        return _ref.apply(this, arguments);
    };
}();
export var verifyCode = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(code) {
        var payload;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    payload = {
                        code: code
                    };
                    _ctx.next = 3;
                    return callCustomEndpoint("verifyCode", payload);
                case 3:
                    return _ctx.abrupt("return", _ctx.sent);
                case 4:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function verifyCode(code) {
        return _ref.apply(this, arguments);
    };
}();
export var refreshCode = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return callCustomEndpoint("refreshCode", {});
                case 2:
                    return _ctx.abrupt("return", _ctx.sent);
                case 3:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function refreshCode() {
        return _ref.apply(this, arguments);
    };
}();
