export var UserAccountModal;
(function(UserAccountModal) {
    UserAccountModal["FORGET_PASSWORD"] = "forget_password";
    UserAccountModal["LOGIN"] = "login";
    UserAccountModal["REGISTRATION"] = "registration";
    UserAccountModal["EMAIL_VERIFICATION"] = "email_verification";
    UserAccountModal["RESET_PASSWORD_EMAIL"] = "reset_password_email";
    UserAccountModal["PASSWORD_RESET_SUCCESSFULLY"] = "password_reset_successfully";
    UserAccountModal["PASSWORD_CHANGED_SUCCESSFULLY"] = "password_changed_successfully";
    UserAccountModal["EMAIL_VERIFICATION_CHECKOUT"] = "email_verification_checkout";
    UserAccountModal["EMAIL_VERIFIED_SUCCESSFULLY"] = "email_verified_successfully";
    UserAccountModal["DELETE_DRIVER"] = "delete_driver";
    UserAccountModal["CONFIRM_RETURN"] = "confirm_return";
    UserAccountModal["DRIVER_ADDED_ACTIVE"] = "driver_added_active";
    UserAccountModal["DRIVER_ADDED_PENDING"] = "driver_added_pending";
    UserAccountModal["RETURN_CONFIRMED"] = "return_confirmed";
    UserAccountModal["CONFIRM_CHANGING_PAYMENT_METHOD"] = "confirm_changing_payment_method";
    UserAccountModal["CONFIRM_CHANGING_PAYMENT_METHOD_D2C"] = "confirm_changing_payment_method_d2c";
    UserAccountModal["PAYMENT_CHANGED_SUCCESSFULLY"] = "payment_changed_successfully";
    UserAccountModal["PAYMENT_CHANGED_SUCCESSFULLY_D2C"] = "payment_changed_successfully_d2c";
    UserAccountModal["ERROR"] = "error";
    UserAccountModal["UNAUTHORISED_ACCESS"] = "unauthorised_access";
    UserAccountModal["SERVER_SIDE_ERROR"] = "server_side_error";
    UserAccountModal["NEW_SUBSCRIPTION"] = "new_subscription";
    UserAccountModal["EDIT_HANDOVER_ADDRESS_SUCCESS"] = "handover_address_change_success";
    UserAccountModal["ACCOUNT_DELETION_REQUESTED"] = "account_deletion_requested";
    UserAccountModal["CONFIRM_DELETING_ACCOUNT_WITHOUT_ACTIVE_SUBSCRIPTION"] = "confirm_deleting_account_without_active_subscriptions";
    UserAccountModal["ACCOUNT_DELETION_REQUESTED_SUCCESSFULLY"] = "account_without_active_subscriptions_delete_request_sent_successfully";
    UserAccountModal["CONFIRM_DELETING_ACCOUNT_WITH_ACTIVE_SUBSCRIPTION"] = "confirm_deleting_account_with_active_subscription";
    UserAccountModal["SCHEDULE_SERVICE_SUCCEED"] = "schedule_service_success";
    UserAccountModal["SCHEDULE_SERVICE_FAILED"] = "schedule_service_failure";
    UserAccountModal["REPORT_DAMAGE_SUCCEED"] = "report_damage_success";
    UserAccountModal["REPORT_DAMAGE_FAILED"] = "report_damage_failure";
    UserAccountModal["RETENTION_ALTERNATIVE_OPTIONS"] = "retention_alternative_options";
    UserAccountModal["EDIT_HANDOVER_ADDRESS_REMINDER"] = "edit_handover_address_reminder";
    UserAccountModal["REGISTRATION_CERTIFICATE_ACEEPTANCE_CRITERIA"] = "registration_certificate_acceptance_criteria";
})(UserAccountModal || (UserAccountModal = {}));
