import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { interactionTrackingEvent, TrackingEventName } from "@finn/ua-tracking";
import { useCallback } from "react";
export var useRegisterFormTracker = function() {
    var trackSubmitClick = useCallback(function(data) {
        interactionTrackingEvent(TrackingEventName.REGISTER_CTA_CLICKED, _object_spread({
            authenticationMethod: "email"
        }, data || {}));
    }, []);
    var trackFailure = useCallback(function() {
        var error = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "", data = arguments.length > 1 ? arguments[1] : void 0;
        interactionTrackingEvent(TrackingEventName.ACCOUNT_CREATION_FAILED, _object_spread({
            authenticationMethod: "email",
            error: error
        }, data || {}));
    }, []);
    return {
        trackSubmitClick: trackSubmitClick,
        trackFailure: trackFailure
    };
};
