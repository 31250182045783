import * as Yup from "yup";
import { validatePasswordRegex } from "../yup";
export var createValidationSchema = function() {
    return Yup.object().shape({
        currentPassword: Yup.string().required("yup.required"),
        newPassword: Yup.string().required("yup.required").notOneOf([
            Yup.ref("currentPassword"),
            null
        ], "userAccount.changePassword.old_password_reuse").matches(validatePasswordRegex, "yup.required"),
        confirmNewPassword: Yup.string().required("yup.required").oneOf([
            Yup.ref("newPassword"),
            null
        ], "userAccount.changePassword.passwords_do_not_match")
    }, [
        [
            "newPassword",
            "currentPassword"
        ]
    ]);
};
export var validationSchema = createValidationSchema();
