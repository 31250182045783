import { ModalContainer, ModalKey } from '@finn/ua-modals';
import { FakeDoorModal } from '@finn/ui-modules';
import React from 'react';

import DevModal from '~/modules/dev/DevModal';

export const GlobalModals: React.FC = () => {
  // todo: move modals that are not required in global scope to reduce bundle
  return (
    <>
      <ModalContainer modalKey={ModalKey.DEV_MODAL} ModalComponent={DevModal} />
      <FakeDoorModal />
    </>
  );
};
