import { interactionTrackingEvent, TrackingEventName } from "@finn/ua-tracking";
import { useEffect } from "react";
export var useRegistrationModalTracker = function() {
    useEffect(function() {
        interactionTrackingEvent(TrackingEventName.REGISTER_MODAL_OPENED, {});
        return function() {
            interactionTrackingEvent(TrackingEventName.REGISTER_MODAL_CLOSED, {});
        };
    }, []);
};
