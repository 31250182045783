import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { CookieKeys, getClientBooleanCookie } from "@finn/ui-utils/lib/cookies";
import { isServer } from "@finn/ui-utils/lib/server";
import { getSession as getSessionNext, signOut, useSession as useSessionNext } from "next-auth/client";
export var SOCIAL_LOGIN_PROVIDER;
(function(SOCIAL_LOGIN_PROVIDER) {
    SOCIAL_LOGIN_PROVIDER["GOOGLE"] = "google";
    SOCIAL_LOGIN_PROVIDER["APPLE"] = "apple";
})(SOCIAL_LOGIN_PROVIDER || (SOCIAL_LOGIN_PROVIDER = {}));
export var getSession = function(options) {
    var session = getSessionNext(options);
    return session;
};
export var useSession = function() {
    var ref = _sliced_to_array(useSessionNext(), 2), session = ref[0], loading = ref[1];
    if (!isServer()) {
        var signOutInProgress = getClientBooleanCookie(CookieKeys.SIGNOUT_IN_PROGRESS);
        if (signOutInProgress) {
            signOut({
                redirect: false
            });
            return [
                null,
                false
            ];
        }
    }
    return [
        session,
        loading
    ];
};
export var getFinnSessionId = function() {
    var requestId = new Date().getTime().toString(36) + Math.random().toString(36).substr(2, 12);
    return requestId;
};
