import { ReactComponent as Alert } from "./alert.svg";
import { ReactComponent as Calendar } from "./calendar.svg";
import { ReactComponent as Checked } from "./checked.svg";
import { ReactComponent as Dashboard } from "./dashboard.svg";
import { ReactComponent as Delete } from "./delete.svg";
import { ReactComponent as EmailSent } from "./email-sent.svg";
import { ReactComponent as Error } from "./error.svg";
import Profile from "./Profile";
import { ReactComponent as SuccessOperation } from "./success-operation.svg";
import { ReactComponent as Time } from "./time.svg";
import { ReactComponent as Unchecked } from "./unchecked.svg";
export var UserAccount = {
    Profile: Profile,
    Dashboard: Dashboard,
    Checked: Checked,
    Unchecked: Unchecked,
    EmailSent: EmailSent,
    SuccessOperation: SuccessOperation,
    Delete: Delete,
    Time: Time,
    Calendar: Calendar,
    Error: Error,
    Alert: Alert
};
