import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { TrackingEventName, useTrackingStore } from "@finn/ua-tracking";
import { useCallback } from "react";
export var useLoginFormTracker = function() {
    var track = useTrackingStore(function(state) {
        return state.track;
    });
    var trackSubmitClick = useCallback(function(data) {
        track(TrackingEventName.CTA_CLICKED, {
            location: "login",
            additionalProps: _object_spread({
                authenticationMethod: "email"
            }, data || {})
        });
    }, []);
    var trackFailure = useCallback(function() {
        var error = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "", data = arguments.length > 1 ? arguments[1] : void 0;
        track(TrackingEventName.ACCOUNT_LOGIN_FAILED, {
            additionalProps: _object_spread({
                authenticationMethod: "email",
                error: error
            }, data || {})
        });
    }, []);
    var trackForgotPasswordClick = useCallback(function(data) {
        track(TrackingEventName.CTA_CLICKED, {
            location: "Forgot Password",
            additionalProps: _object_spread({
                authenticationMethod: "email"
            }, data || {})
        });
    }, []);
    var b2bLoginClicked = useCallback(function() {
        track(TrackingEventName.CTA_CLICKED, {
            location: "B2B Login"
        });
    }, []);
    return {
        trackSubmitClick: trackSubmitClick,
        trackFailure: trackFailure,
        trackForgotPasswordClick: trackForgotPasswordClick,
        b2bLoginClicked: b2bLoginClicked
    };
};
