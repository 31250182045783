import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import regeneratorRuntime from "/home/runner/work/finn-auto-ui-frontend/finn-auto-ui-frontend/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { config } from "@finn/ui-utils";
var currentSessionId;
var currentSessionNumber;
function fetchSessionInfoFromGTag(gtag) {
    return _fetchSessionInfoFromGTag.apply(this, arguments);
}
function _fetchSessionInfoFromGTag() {
    _fetchSessionInfoFromGTag = _async_to_generator(regeneratorRuntime.mark(function _callee(gtag) {
        var sessionIdPromise, sessionNumberPromise, ref, session_id, session_number;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    if (!(currentSessionId !== undefined && currentSessionNumber !== undefined)) {
                        _ctx.next = 2;
                        break;
                    }
                    return _ctx.abrupt("return", {
                        session_id: currentSessionId,
                        session_number: currentSessionNumber
                    });
                case 2:
                    sessionIdPromise = new Promise(function(resolve) {
                        gtag("get", config.GOOGLE_ANALYTICS_4_WEB, "session_id", resolve);
                    });
                    sessionNumberPromise = new Promise(function(resolve) {
                        gtag("get", config.GOOGLE_ANALYTICS_4_WEB, "session_number", resolve);
                    });
                    _ctx.t0 = _sliced_to_array;
                    _ctx.next = 7;
                    return Promise.all([
                        sessionIdPromise,
                        sessionNumberPromise, 
                    ]);
                case 7:
                    _ctx.t1 = _ctx.sent;
                    ref = (0, _ctx.t0)(_ctx.t1, 2);
                    session_id = ref[0];
                    session_number = ref[1];
                    // Save session info in global variables
                    currentSessionId = session_id;
                    currentSessionNumber = session_number;
                    return _ctx.abrupt("return", {
                        session_id: session_id,
                        session_number: session_number
                    });
                case 14:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return _fetchSessionInfoFromGTag.apply(this, arguments);
}
export var getCurrentSessionData = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(gtag) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.prev = 0;
                    _ctx.next = 3;
                    return fetchSessionInfoFromGTag(gtag);
                case 3:
                    return _ctx.abrupt("return", _ctx.sent);
                case 6:
                    _ctx.prev = 6;
                    _ctx.t0 = _ctx["catch"](0);
                    console.error("Error retrieving session data:", _ctx.t0);
                    return _ctx.abrupt("return", {
                        session_id: undefined,
                        session_number: undefined
                    });
                case 10:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                0,
                6
            ]
        ]);
    }));
    return function getCurrentSessionData(gtag) {
        return _ref.apply(this, arguments);
    };
}();
