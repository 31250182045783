import { useContext } from "react";
import { FeatureContext } from "./FeatureContext";
/**
 * Returns true if the AB test value for this user is 'b'
 * @param name
 * @returns
 */ export var useIsABVariant = function(name) {
    var features = useContext(FeatureContext);
    return features[name] === "b";
};
export var useIsCVariant = function(name) {
    var features = useContext(FeatureContext);
    return features[name] === "c";
};
