import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/runner/work/finn-auto-ui-frontend/finn-auto-ui-frontend/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { ModalKey, useOpenModal } from "@finn/ua-modals";
import { CookieKeys, sha256 } from "@finn/ui-utils";
import useHotkeys from "@reecelucas/react-use-hotkeys";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";
var DEV_HOT_KEY = "Control+F6";
// type dev in a non-input field in a short time
var DEV_HOT_KEY_2 = "d e v";
// cmd+F6 or window+F6
var DEV_HOT_KEY_3 = "Meta+F6";
var TAP_COUNT_TO_OPEN_MODAL = 6;
var TAP_COUNT_TIMEOUT = 2000;
var SavedPassHash = "8075cc17eaf2b1fd73e527dcdb3e914ac10f81f30e48a1dc32c7dfa87b6c3a1d";
var useSetDevMode = function() {
    var ref = useState(Cookies.get(CookieKeys.DEV_PASS)), devPass = ref[0], setDevPass = ref[1];
    var openModal = useOpenModal();
    var ref1 = useState(false), isDevAuthenticated = ref1[0], setIsDevAuthenticated = ref1[1];
    var ref2 = useState(0), tapCount = ref2[0], setTapCount = ref2[1];
    var triggerDevModal = useCallback(function() {
        setTapCount(function(oldVal) {
            return oldVal + 1;
        });
    }, []);
    useEffect(function() {
        var generateHash = function() {
            var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
                var hash;
                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                    while(1)switch(_ctx.prev = _ctx.next){
                        case 0:
                            _ctx.next = 2;
                            return sha256(devPass || "");
                        case 2:
                            hash = _ctx.sent;
                            if (hash === SavedPassHash) {
                                setIsDevAuthenticated(true);
                            } else {
                                setIsDevAuthenticated(false);
                            }
                        case 4:
                        case "end":
                            return _ctx.stop();
                    }
                }, _callee);
            }));
            return function generateHash() {
                return _ref.apply(this, arguments);
            };
        }();
        generateHash();
    }, [
        devPass
    ]);
    var openDevModal = useCallback(_async_to_generator(regeneratorRuntime.mark(function _callee() {
        var pass, hash;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    if (!isDevAuthenticated) {
                        _ctx.next = 4;
                        break;
                    }
                    {
                        openModal(ModalKey.DEV_MODAL);
                    }
                    _ctx.next = 11;
                    break;
                case 4:
                    pass = prompt("Please enter Dev Password", "");
                    _ctx.next = 7;
                    return sha256(pass || "");
                case 7:
                    hash = _ctx.sent;
                    if (hash === SavedPassHash) {
                        openModal(ModalKey.DEV_MODAL);
                    }
                    Cookies.set(CookieKeys.DEV_PASS, pass);
                    setDevPass(pass);
                case 11:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    })), [
        isDevAuthenticated,
        openModal
    ]);
    useEffect(function() {
        var timeoutHandle;
        if (tapCount >= TAP_COUNT_TO_OPEN_MODAL) {
            openDevModal();
        } else if (tapCount > 0) {
            timeoutHandle = setTimeout(function() {
                setTapCount(0);
            }, TAP_COUNT_TIMEOUT);
        }
        return function() {
            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
            }
        };
    }, [
        openDevModal,
        tapCount
    ]);
    useHotkeys([
        DEV_HOT_KEY,
        DEV_HOT_KEY_2,
        DEV_HOT_KEY_3
    ], openDevModal);
    return triggerDevModal;
};
export default useSetDevMode;
