import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _define_property from "@swc/helpers/src/_define_property.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import regeneratorRuntime from "/home/runner/work/finn-auto-ui-frontend/finn-auto-ui-frontend/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { CountryCode, useCurrentLocale } from "@finn/ui-utils";
import dayjs from "dayjs";
import keys from "lodash/keys";
import { useState } from "react";
import { validateEmail, validatePassword } from "./validation";
import { checkPhoneNumberValidation, isValidAge, validNameRegex } from "./yup";
export var Fields;
(function(Fields) {
    Fields["Email"] = "email";
    Fields["Password"] = "password";
    Fields["FirstName"] = "firstname";
    Fields["LastName"] = "lastname";
    Fields["Phone"] = "phone";
    Fields["Birthday"] = "birthday";
})(Fields || (Fields = {}));
export var useForm = function(param) {
    var defaultValues = param.defaultValues, _hasNewPassword = param.hasNewPassword, hasNewPassword = _hasNewPassword === void 0 ? false : _hasNewPassword, _isRegistration = param.isRegistration, isRegistration = _isRegistration === void 0 ? false : _isRegistration;
    var region = useCurrentLocale().region;
    var ref = useState({
        values: defaultValues,
        errors: {
            email: null,
            password: null,
            firstname: null,
            lastname: null,
            phone: null,
            birthday: null
        }
    }), ref1 = ref[0], values = ref1.values, errors = ref1.errors, setFormState = ref[1];
    var setValues = function(newValues) {
        setFormState(function(previousState) {
            return _object_spread_props(_object_spread({}, previousState), {
                values: _object_spread({}, previousState.values, newValues)
            });
        });
    };
    var handleChange = function(event) {
        event.persist();
        setValues(_define_property({}, event.target.name, event.target.value));
    };
    var handlePhoneChange = function(phone) {
        setValues(_define_property({}, Fields.Phone, phone));
    };
    var handleBirthdayChange = function(birthday) {
        setValues(_define_property({}, Fields.Birthday, birthday === null || birthday === void 0 ? void 0 : birthday.toDate()));
    };
    var setError = function(field, error) {
        setFormState(function(previousState) {
            return _object_spread_props(_object_spread({}, previousState), {
                errors: _object_spread_props(_object_spread({}, previousState.errors), _define_property({}, field, error))
            });
        });
    };
    /**
   * Validate field
   * @param field
   * @returns {boolean} true if field is valid
   */ var validate = function(field) {
        switch(field){
            case Fields.Email:
                {
                    var emailValue = values[Fields.Email];
                    var isValidEmail = validateEmail(emailValue || "");
                    if (isValidEmail) {
                        setError(Fields.Email, null);
                        return true;
                    } else {
                        setError(Fields.Email, (emailValue === null || emailValue === void 0 ? void 0 : emailValue.length) ? "yup.email" : "yup.required");
                    }
                    break;
                }
            case Fields.Password:
                {
                    var passwordValue = values[Fields.Password];
                    // force strength check validation only for registration. login should be able to submit any non-empty password
                    var isValidPassword = hasNewPassword ? validatePassword(passwordValue || "") : !!passwordValue;
                    if (isValidPassword) {
                        setError(Fields.Password, null);
                        return true;
                    } else {
                        setError(Fields.Password, (passwordValue === null || passwordValue === void 0 ? void 0 : passwordValue.length) ? hasNewPassword : "yup.required");
                    }
                    break;
                }
            // additional validation for extended account experiment,
            // todo: Whole login/registration form should be converted to Formik or React-hook-form and yup validation in the future
            case Fields.FirstName:
                {
                    if (!isRegistration) {
                        return true;
                    }
                    var firstNameValue = values[Fields.FirstName];
                    if (!firstNameValue) {
                        setError(Fields.FirstName, "yup.required");
                    } else if (!validNameRegex.test(firstNameValue)) {
                        setError(Fields.FirstName, "yup.string");
                    } else {
                        setError(Fields.FirstName, null);
                        return true;
                    }
                    break;
                }
            case Fields.LastName:
                {
                    if (!isRegistration) {
                        return true;
                    }
                    var lastNameValue = values[Fields.LastName];
                    if (!lastNameValue) {
                        setError(Fields.LastName, "yup.required");
                    } else if (!validNameRegex.test(lastNameValue)) {
                        setError(Fields.LastName, "yup.string");
                    } else {
                        setError(Fields.LastName, null);
                        return true;
                    }
                    break;
                }
            case Fields.Birthday:
                {
                    if (!isRegistration) {
                        return true;
                    }
                    var birthdayValue = values[Fields.Birthday];
                    var validDate = dayjs(birthdayValue).isValid();
                    var validAge = birthdayValue && isValidAge(birthdayValue, region);
                    if (!birthdayValue) {
                        setError(Fields.Birthday, "yup.required");
                    } else if (!validDate) {
                        setError(Fields.Birthday, "yup.validDate");
                    } else if (!validAge) {
                        setError(Fields.Birthday, region === CountryCode.US ? "yup.age>=25" : "yup.age>=18");
                    } else {
                        setError(Fields.Birthday, null);
                        return true;
                    }
                    break;
                }
            case Fields.Phone:
                {
                    if (!isRegistration) {
                        return true;
                    }
                    var phoneValue = values[Fields.Phone];
                    var isValidNumber = checkPhoneNumberValidation(phoneValue || "", region);
                    if (!phoneValue) {
                        setError(Fields.Phone, "yup.required");
                    } else if (!isValidNumber) {
                        setError(Fields.Phone, "yup.validPhoneNumber");
                    } else {
                        setError(Fields.Phone, null);
                        return true;
                    }
                    break;
                }
            default:
                {
                    console.error("unknown field");
                    break;
                }
        }
    };
    var handleBlur = function(evt) {
        var field = evt.target.name;
        validate(field);
    };
    var onSubmit = function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(callback) {
            var fields, shouldSubmit, i, field;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        fields = keys(defaultValues);
                        shouldSubmit = true;
                        for(i = 0; i < fields.length; i++){
                            field = fields[i];
                            shouldSubmit = shouldSubmit && validate(field);
                        }
                        if (!shouldSubmit) {
                            _ctx.next = 6;
                            break;
                        }
                        _ctx.next = 6;
                        return callback();
                    case 6:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        }));
        return function onSubmit(callback) {
            return _ref.apply(this, arguments);
        };
    }();
    return {
        handleChange: handleChange,
        handlePhoneChange: handlePhoneChange,
        handleBirthdayChange: handleBirthdayChange,
        handleBlur: handleBlur,
        onSubmit: onSubmit,
        values: values,
        errors: errors,
        setValues: setValues,
        setError: setError
    };
};
