import _define_property from "@swc/helpers/src/_define_property.mjs";
import Cookies from "js-cookie";
import { useIntl } from "react-intl";
import { Locale } from "../../types/localization";
import { CookieKeys } from "../cookies";
import { getLocaleFromContext } from "../locale";
import { getCorrectLocale } from "../localization";
import { formatNumberParts } from "../number";
export { getDefaultRichTextElements } from "./Localization";
export var generateLocalizationHeaders = function(locale) {
    return {
        "X-Language-Tag": locale || Locale.GERMAN_GERMANY
    };
};
export var CURRENCIES = {
    EURO: "EUR",
    USD: "USD"
};
var _obj;
export var LOCALE_CURRENCIES = (_obj = {}, _define_property(_obj, Locale.GERMAN_GERMANY, CURRENCIES.EURO), // TODO en_DE support
// [Locale.ENGLISH_GERMANY]: EURO,
_define_property(_obj, Locale.ENGLISH_USA, CURRENCIES.USD), _obj);
export var getCorrectLocaleString = function(locale) {
    var ref = getCorrectLocale(locale), lang = ref.lang, region = ref.region;
    return "".concat(lang, "-").concat(region);
};
export var constructFormattedPriceResponse = function(intl, value) {
    var maximumFractionDigits = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 2, forceDigits = arguments.length > 3 ? arguments[3] : void 0;
    var parsedLocale = getCorrectLocaleString(intl.locale);
    var currency = LOCALE_CURRENCIES[parsedLocale];
    return formatNumberParts(intl.formatNumberToParts(value, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: forceDigits ? maximumFractionDigits : 0,
        maximumFractionDigits: maximumFractionDigits
    }));
};
export var getFormattedPrice = function(intl, value) {
    var maximumFractionDigits = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 2, forceDigits = arguments.length > 3 ? arguments[3] : void 0;
    return constructFormattedPriceResponse(intl, value, maximumFractionDigits, forceDigits);
};
export var constructFormattedNumberResponse = function(intl, value) {
    var maximumFractionDigits = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 2, forceDigits = arguments.length > 3 ? arguments[3] : void 0;
    var roundedValue = maximumFractionDigits ? value : Math.ceil(value);
    return intl.formatNumber(roundedValue, {
        minimumFractionDigits: forceDigits ? maximumFractionDigits : 0,
        maximumFractionDigits: maximumFractionDigits
    });
};
export var getFormattedNumber = function(intl, value) {
    var maximumFractionDigits = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 2, forceDigits = arguments.length > 3 ? arguments[3] : void 0;
    return constructFormattedNumberResponse(intl, value, maximumFractionDigits, forceDigits);
};
export var generateLocalizationHeadersFromCookie = function() {
    var localeCookie = Cookies.get(CookieKeys.NEXT_LOCALE);
    return generateLocalizationHeaders(localeCookie);
};
export var generateLocalizationHeadersFromContext = function(ctx) {
    return generateLocalizationHeaders(getLocaleFromContext(ctx));
};
// TODO hack to support multilocales
export var withLocaleRedirect = function(url, locale) {
    return locale === Locale.GERMAN_GERMANY || !locale ? url : "/".concat(locale).concat(url);
};
export var useFormattedPrice = function(value) {
    var maximumFractionDigits = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 2, forceDigits = arguments.length > 2 ? arguments[2] : void 0;
    var intl = useIntl();
    return constructFormattedPriceResponse(intl, value, maximumFractionDigits, forceDigits);
};
