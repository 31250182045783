import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/runner/work/finn-auto-ui-frontend/finn-auto-ui-frontend/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { interactionTrackingEvent, TrackingEventName } from "@finn/ua-tracking";
import { CookieKeys, getClientCookie, getSession, isServer, setClientCookie } from "@finn/ui-utils";
var HUBSPOT_ID_COOKIE_EXPIRATION_DAYS = 90;
export var checkAccountSwitch = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
        var ref, session, idFromCookie, idFromSession;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    ;
                    _ctx.next = 3;
                    return getSession();
                case 3:
                    session = _ctx.sent;
                    if (!(!session || isServer())) {
                        _ctx.next = 6;
                        break;
                    }
                    return _ctx.abrupt("return");
                case 6:
                    idFromCookie = String(getClientCookie(CookieKeys.HUBSPOT_ID) || "");
                    idFromSession = String((ref = session.user) === null || ref === void 0 ? void 0 : ref.hs_contact_id);
                    if (!idFromCookie) {
                        setClientCookie(CookieKeys.HUBSPOT_ID, idFromSession, HUBSPOT_ID_COOKIE_EXPIRATION_DAYS);
                    }
                    if (idFromCookie && idFromSession && idFromCookie !== idFromSession) {
                        interactionTrackingEvent(TrackingEventName.ACCOUNT_SWITCHED, {
                            previousAccountId: idFromCookie,
                            newAccountId: idFromSession
                        });
                        setClientCookie(CookieKeys.HUBSPOT_ID, idFromSession, HUBSPOT_ID_COOKIE_EXPIRATION_DAYS);
                    }
                case 10:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function checkAccountSwitch() {
        return _ref.apply(this, arguments);
    };
}();
