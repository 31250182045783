import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/runner/work/finn-auto-ui-frontend/finn-auto-ui-frontend/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
export var savePushToken = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(param) {
        var contactId, expoToken, deviceToken;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    contactId = param.contactId, expoToken = param.expoToken, deviceToken = param.deviceToken;
                    _ctx.next = 3;
                    return fetch("/api/savePushToken", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            contact_id: contactId,
                            push_token: expoToken,
                            device_push_token: deviceToken
                        })
                    });
                case 3:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function savePushToken(_) {
        return _ref.apply(this, arguments);
    };
}();
